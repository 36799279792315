import { STATUSES } from '@ftbpro/mm-admin-ui-components';
import { ExperimentStatus } from 'containers/experiments/store/types';

export const EXPERIMENTS = 'experiments';

const BASE_ROUTE = '/experiments';

export const EXPERIMENTS_ROUTES = {
  BASE: BASE_ROUTE,
  NEW: `${BASE_ROUTE}/new`,
};

export const STATUSES_TEXT = {
  DRAFT: 'Draft',
  RUNNING: 'Running',
  CANCELED: 'Canceled',
  COMPLETED: 'Completed',
  PENDING: 'Pending',
  FAILED: 'Failed',
};

export const STATUS_PROPS = {
  [ExperimentStatus.Pending]: {
    status: STATUSES.NEW,
    text: STATUSES_TEXT.DRAFT,
  },
  [ExperimentStatus.Active]: {
    status: STATUSES.SUCCESS,
    text: STATUSES_TEXT.RUNNING,
  },
  [ExperimentStatus.Cancelled]: {
    status: STATUSES.ARCHIVED,
    text: STATUSES_TEXT.CANCELED,
  },
  [ExperimentStatus.Completed]: {
    status: STATUSES.ARCHIVED,
    text: STATUSES_TEXT.COMPLETED,
  },
  [ExperimentStatus.Activating]: {
    status: STATUSES.PENDING,
    text: STATUSES_TEXT.PENDING,
  },
  [ExperimentStatus.Cancelling]: {
    status: STATUSES.PENDING,
    text: STATUSES_TEXT.PENDING,
  },
  [ExperimentStatus.Failed]: {
    status: STATUSES.FAILURE,
    text: STATUSES_TEXT.FAILED,
  },
};

export enum ScopeType {
  user = 'user',
  session = 'session'
}
