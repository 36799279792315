import React from 'react';
import { Redirect, Switch } from 'react-router';
import { MONETIZATION_ROUTE_ROUTES } from 'containers/commercial/monetization/monetizationNavigator';
import { EXPERIMENTS_ROUTES } from 'containers/experiments/experiments.constants';
import { ANALYTICS_ROUTES } from 'containers/reports/reports.constants';
import { ADS_TXT_ROUTES } from 'containers/adsTxt/adsTxt.constants';
import { ALERTS_ROUTES } from 'containers/alerts/alerts.constants';
import { ProtectedRoute, ProtectedRouteContext } from '../../core/components/routes/ProtectedRoute';
import { HQRole } from '../../core/store/user/roles.constants.hq';
import { MARKETPLACE_ROUTES } from '../../containers/marketplace/marketplaceNavigator';
import { ADMINISTRATION_ROUTES } from '../../containers/administration/administration.constants';
import { PROMOTIONS_ROUTES } from '../../containers/commercial/promotions/promotionsNavigator';
import { SITE_POLICY_ROUTES } from '../../containers/commercial/sitePolicy/sitePolicyNavigator';
import { AD_SERVER_ROUTES } from '../../containers/adServer/adServerNavigator';
import { ORGANIZATION_ROUTES } from '../../containers/organizationHq/organization.constants';
import { CRAFT_ROUTES } from '../../containers/craftHq/craft.constants';
import { ROUTE_VMS_PREFIX } from '../../containers/vms/vms.constants';
import { ONBOARDING_ROUTES } from '../../containers/creatorsOnboarding/onboarding.constants';
import { SEO_ROUTES } from '../../containers/seo/seo.constants';

const LazyVMS = React.lazy(() => import(/* webpackChunkName: "vms" */ '../../containers/vms/index.hq'));
const LazyMarketplace = React.lazy(() => import(/* webpackChunkName: "marketplace" */ '../../containers/marketplace'));
const LazyAdministration = React.lazy(() => import(/* webpackChunkName: "administration" */ '../../containers/administration/index.hq'));
const LazyAlerts = React.lazy(() => import(/* webpackChunkName: "alerts" */ '../../containers/alerts'));
const LazyPromotions = React.lazy(() => import(/* webpackChunkName: "promotions" */ '../../containers/commercial/promotions'));
const LazySitePolicy = React.lazy(() => import(/* webpackChunkName: "sitePolicy" */ '../../containers/commercial/sitePolicy'));
const LazyMonetization = React.lazy(() => import(/* webpackChunkName: "monetization" */ '../../containers/commercial/monetization/index.hq'));
const LazyOrganization = React.lazy(() => import(/* webpackChunkName: "organization" */ '../../containers/organizationHq'));
const LazyCraft = React.lazy(() => import(/* webpackChunkName: "craft" */ '../../containers/craftHq'));
const LazySEO = React.lazy(() => import(/* webpackChunkName: "seo" */ '../../containers/seo'));
const LazyExperiments = React.lazy(() => import(/* webpackChunkName: "experiments" */ '../../containers/experiments'));
const LazyAdsTxt = React.lazy(() => import(/* webpackChunkName: "adsTxt" */ '../../containers/adsTxt'));
const LazyReports = React.lazy(() => import(/* webpackChunkName: "craft" */ '../../containers/reports/index.hq'));
const LazyOnboarding = React.lazy(() => import(/* webpackChunkName: "onboarding" */ '../../containers/creatorsOnboarding'));
const LazyAdServer = React.lazy(() => import(/* webpackChunkName: "adServer" */ '../../containers/adServer'));
// TODO: try to change into a config object instead of a component ?

export const Routes = ({ defaultPage }) => (
  <ProtectedRouteContext.Provider value={{
    routeConfig: {
      allowNoProperties: true,
      allowNoOrganization: true,
    },
  }}
  >
    <Switch>
      <ProtectedRoute
        path={ALERTS_ROUTES.BASE}
        component={LazyAlerts}
        allowedHQRole={[HQRole.DataAlertsManager]}
      />
      <ProtectedRoute
        path={ANALYTICS_ROUTES.BASE}
        component={LazyReports}
      />
      <ProtectedRoute
        allowedHQRole={[HQRole.InjectionsManager, HQRole.VMSAdmin]}
        path={ROUTE_VMS_PREFIX}
        component={LazyVMS}
      />
      <ProtectedRoute
        path={MARKETPLACE_ROUTES.MARKETPLACE_BASE}
        component={LazyMarketplace}
      />
      <ProtectedRoute
        path={ADMINISTRATION_ROUTES.BASE}
        component={LazyAdministration}
      />
      <ProtectedRoute
        path={PROMOTIONS_ROUTES.PROMOTIONS_BASE}
        component={LazyPromotions}
        allowedHQRole={[HQRole.PromotionsVideoManager, HQRole.PromotionsViewer, HQRole.PromotionsWidgetManager]}
      />
      <ProtectedRoute
        path={SITE_POLICY_ROUTES.SITE_POLICY_BASE}
        component={LazySitePolicy}
        allowedHQRole={[HQRole.SitePolicyManager, HQRole.SitePolicyViewer]}
      />
      <ProtectedRoute
        path={MONETIZATION_ROUTE_ROUTES.MONETIZATION_ROUTE_BASE}
        component={LazyMonetization}
        allowedHQRole={[HQRole.SitePolicyManager, HQRole.SitePolicyViewer]}
      />
      <ProtectedRoute
        path={ONBOARDING_ROUTES.BASE}
        component={LazyOnboarding}
        allowedHQRole={[HQRole.OnboardingAdmin]}
      />
      <ProtectedRoute
        allowedHQRole={[HQRole.SetupManager, HQRole.PartnerManagementAdmin]}
        path={ORGANIZATION_ROUTES.BASE}
        component={LazyOrganization}
      />
      <ProtectedRoute
        allowedHQRole={[HQRole.SetupManager, HQRole.SeoManager]}
        path={CRAFT_ROUTES.BASE}
        component={LazyCraft}
      />
      <ProtectedRoute
        allowedHQRole={[HQRole.SetupManager, HQRole.SeoManager]}
        path={SEO_ROUTES.BASE}
        component={LazySEO}
      />
      <ProtectedRoute
        allowedHQRole={[HQRole.ExperimentsManager]}
        path={EXPERIMENTS_ROUTES.BASE}
        component={LazyExperiments}
      />
      <ProtectedRoute
        path={AD_SERVER_ROUTES.AD_SERVER_BASE}
        component={LazyAdServer}
      />
      <ProtectedRoute
        allowedHQRole={[HQRole.AdsTxtAdmin]}
        path={ADS_TXT_ROUTES.BASE}
        component={LazyAdsTxt}
      />
      <ProtectedRoute
        path="/"
        component={() => <Redirect to={defaultPage} />}
      />
    </Switch>
  </ProtectedRouteContext.Provider>
);
