import { SortDirection } from '@ftbpro/mm-admin-ui-components';
import { Pagination } from 'core/types';
import { ValidationField } from 'core/utils/validationsBeta/types';
import { ScopeType } from '../experiments.constants';

export enum ExperimentStatus {
  Pending = 'pending',
  Active = 'active',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Activating = 'activating',
  Cancelling = 'cancelling',
  Failed = 'failed'
}

export interface GroupData {
  id: string,
  percentTraffic: number
}

export interface PropertyData {
  id: string,
  name: string
}

export interface PropertiesDetails {
  tenant: string,
  organization: string,
  properties: PropertyData[]
}

export interface Segemantation {
  propertiesDetails : PropertiesDetails[]
}
export interface ExperimentDataFromServer {
  id: string,
  queryParameter: string,
  startDate: string,
  endDate: string,
  metrics: string,
  description: string,
  developerName: string,
  productManagerName: string,
  duration: number,
  segmentation: Segemantation,
  groups: GroupData[],
  createdAt: string,
  updatedAt: string,
  archived: boolean,
  status: ExperimentStatus,
  conclusionsLink: string,
  scope: ScopeType,
  totalTraffic: number
}

export interface ExperimentDataForClient {
  id: string,
  queryParameter: string
  properties: {
    properties: string,
    index: number
  },
  lastModified: string,
  startDate: string,
  daysLeft: number | null,
  createdBy: string,
  status: ExperimentStatus,
  actions: {
    id: string
    status: ExperimentStatus
  }
  expand: number
}

export interface SortingData {
  sortBy: string
  order: SortDirection
}
export interface ExperimentsParams extends Partial<SortingData> {
  statuses: string[]
  id?: string
  archived?: boolean
  createdBy?: string
}

export enum ExperimentDetailsKey {
  QueryParameter = 'queryParameter',
  Duration = 'duration',
  Description = 'description',
  Metrics = 'metrics',
  DeveloperName = 'developerName',
  ProductManagerName = 'productManagerName',
}

export interface Groups {
  [key: string]: string
}
export interface Experiment {
  details: Record<ExperimentDetailsKey, ValidationField<string>>,
  totalTraffic: ValidationField<string>,
  groups: Groups,
  segmentation: Segemantation,
  conclusionsLink: string
  scope: ScopeType
  status: ExperimentStatus
}

export interface ListItemForClient {
  value: string,
  content: string
}

export interface ListItemFromServer {
  id: string,
  name: string
}
export interface ExperimentsSliceState {
  loading: boolean,
  experimentsList: ExperimentDataForClient[],
  error: string
  queryParams: ExperimentsParams
  pagination: Pagination
  productManagersList: string[]
  currentExperiment: Experiment
  propertiesList: ListItemForClient[]
  organizationList: ListItemForClient[]
  showStopExperimentlModal: boolean
  showDuplicateExperimentlModal: boolean
  versionsList: Version[]
}

export interface Version {
  id: string,
  experiments: ExperimentDataFromServer[]
}
