import { navigateToPage } from '../../../core/routing/history';

const MONETIZATION_ROUTE = 'monetization';
const BIDDERS_ROUTE = 'bidders';
const TARGETING = 'targeting';
const DEMAND_CONFIGURATION = 'demand-configuration';
const POLICIES = 'policies';
const POLICY = 'policy';
const PUBLISHERS = 'publishers';

export const MONETIZATION_ROUTE_ROUTES = {
  MONETIZATION_ROUTE_BASE: `/${MONETIZATION_ROUTE}`,
  BIDDERS: `/${MONETIZATION_ROUTE}/${BIDDERS_ROUTE}`,
  TARGETING: `/${MONETIZATION_ROUTE}/${TARGETING}`,
  DEMAND_CONFIGURATION: `/${MONETIZATION_ROUTE}/${DEMAND_CONFIGURATION}`,
  POLICIES: `/${MONETIZATION_ROUTE}/${POLICIES}`,
  POLICY: `/${MONETIZATION_ROUTE}/${POLICY}`,
  PUBLISHERS: `/${MONETIZATION_ROUTE}/${PUBLISHERS}`,
};

export const monetizationNavigator = {
  toBiddersPage() {
    navigateToPage(MONETIZATION_ROUTE_ROUTES.BIDDERS);
  },
  goToBidderEditPage: (id: string) => {
    navigateToPage(`${MONETIZATION_ROUTE_ROUTES.BIDDERS}/${id}`);
  },
  toTargetingPage(publisherId: string) {
    navigateToPage(`${MONETIZATION_ROUTE_ROUTES.TARGETING}/${publisherId}`);
  },
  toEditDemandConfigurationPage(publisherId: string) {
    navigateToPage(`${MONETIZATION_ROUTE_ROUTES.DEMAND_CONFIGURATION}/${publisherId}`);
  },
  toEditPoliciesPage(publuisherId: string, policyId: string) {
    navigateToPage(`${MONETIZATION_ROUTE_ROUTES.POLICIES}/${publuisherId}/${policyId}`);
  },
  toPoliciesPage(publuisherId: string) {
    navigateToPage(`${MONETIZATION_ROUTE_ROUTES.POLICIES}/${publuisherId}`);
  },
  toPublishersPage() {
    navigateToPage(MONETIZATION_ROUTE_ROUTES.PUBLISHERS);
  },
  goToPublisherCustomizationPage: (id: string) => {
    navigateToPage(`${MONETIZATION_ROUTE_ROUTES.PUBLISHERS}/${id}`);
  },
};
