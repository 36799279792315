import { navigateToPage } from '../../../core/routing/history';

const SITE_POLICY_ROUTE = 'site-policy';
const POLICIES_ROUTE = 'policies';
const HEADER_BIDDERS_ROUTE = 'header-bidders';

export const SITE_POLICY_ROUTES = {
  SITE_POLICY_BASE: `/${SITE_POLICY_ROUTE}`,
  POLICIES: `/${SITE_POLICY_ROUTE}/${POLICIES_ROUTE}`,
  CREATE_POLICY: `/${SITE_POLICY_ROUTE}/${POLICIES_ROUTE}/new`,
  EDIT_POLICY: `/${SITE_POLICY_ROUTE}/${POLICIES_ROUTE}/:id`,
  HEADER_BIDDERS: `/${SITE_POLICY_ROUTE}/${HEADER_BIDDERS_ROUTE}`,
  EDIT_HEADER_BIDDERS: `/${SITE_POLICY_ROUTE}/${HEADER_BIDDERS_ROUTE}/:publisherId`,
};

export const sitePolicyNavigator = {
  toPoliciesPage() {
    navigateToPage(SITE_POLICY_ROUTES.POLICIES);
  },
  toCreatePolicyPage() {
    navigateToPage(SITE_POLICY_ROUTES.CREATE_POLICY);
  },
  toEditPolicyPage(id: string) {
    navigateToPage(`${SITE_POLICY_ROUTES.POLICIES}/${id}`);
  },
  toEditHeaderBiddersPage(publisherId: string) {
    navigateToPage(`${SITE_POLICY_ROUTES.HEADER_BIDDERS}/${publisherId}`);
  },
};
