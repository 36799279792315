import { navigateToPage } from 'core/routing/history';

const PROMOTIONS_ROUTE = 'promotions';
const CAMPAIGNS_ROUTE = 'campaigns';
const ASSETS_ROUTE = 'assets';

export const PROMOTIONS_ROUTES = {
  PROMOTIONS_BASE: `/${PROMOTIONS_ROUTE}`,
  CAMPAIGNS: `/${PROMOTIONS_ROUTE}/${CAMPAIGNS_ROUTE}`,
  EDIT_CAMPAIGN: `/${PROMOTIONS_ROUTE}/${CAMPAIGNS_ROUTE}/:campaignGroupId`,
  ASSETS: `/${PROMOTIONS_ROUTE}/${ASSETS_ROUTE}`,
};

export const CAMPAIGN_ID_QUERY_PARAM = 'campaign';

export const promotionsNavigator = {
  toCampaignsPage() {
    navigateToPage(PROMOTIONS_ROUTES.CAMPAIGNS);
  },
  toEditCampaignPage(campaignGroupId: string, campaignId?: string) {
    navigateToPage(`${PROMOTIONS_ROUTES.CAMPAIGNS}/${campaignGroupId}${campaignId ? `?${CAMPAIGN_ID_QUERY_PARAM}=${campaignId}` : ''}`);
  },
  toAssetsPage() {
    navigateToPage(PROMOTIONS_ROUTES.ASSETS);
  },
};
