import { Table } from '@ftbpro/mm-admin-ui-components';
import {
  ProposalSliceState,
  ProposalDashboardSliceState,
  Proposal,
  sortByParam,
} from './store/types';

const ONBOARDING_ROUTE = 'onboarding';
const PROPOSALS_ROUTE = 'proposals';
const REVIEW_ROUTE = 'proposal';
const OFFER_ROUTE = 'offer';

export const ONBOARDING_ROUTES = {
  BASE: `/${ONBOARDING_ROUTE}`,
  PROPOSALS: `/${ONBOARDING_ROUTE}/${PROPOSALS_ROUTE}`,
  REVIEW_PROPOSAL: `/${ONBOARDING_ROUTE}/${REVIEW_ROUTE}`,
  OFFER: `/${ONBOARDING_ROUTE}/${OFFER_ROUTE}`,
};

export const ONBOARDING = 'onboarding';
export const ROOT_SLICE = ONBOARDING;

export const PROPOSAL_INITIAL_STATE: ProposalSliceState = {
  currentProposal: {} as Proposal,
  offer: {},
  sites: [],
  loading: false,
  error: '',
  contractsErrors: {},
  isOfferPageModalOpen: false,
  isRejectionModalOpen: false,
  proposalPageScroll: {
    top: false,
    offer: false,
    answers: false,
    comments: false,
  },
};

export const PROPOSALS_DASHBOARD_INITIAL_STATE: ProposalDashboardSliceState = {
  loading: false,
  proposals: [],
  error: '',
  queryParams: {
    search: '',
    sortingConfig: {
      direction: Table.SortDirections.DESC,
      sortByKey: sortByParam.submissionDate,
    },
  },
  pagination: {
    count: 0,
    next: '',
    prev: '',
  },
};
