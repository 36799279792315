import { STATUSES } from '@ftbpro/mm-admin-ui-components';

const BASE_ROUTE = '/organizations';
const SETUP = '/setup';
const PARTNERS = '/partners';

export const ORGANIZATION_ROUTES = {
  BASE: BASE_ROUTE,
  SETUP: `${BASE_ROUTE}${SETUP}`,
  NEW: `${BASE_ROUTE}${SETUP}/new`,
  PARTNERS: `${BASE_ROUTE}${PARTNERS}`,
  PARTNERS_NEW: `${BASE_ROUTE}${PARTNERS}/new`,
};

export enum Status {
  Active = 'active',
  Demo = 'demo',
  Inactive = 'inactive',
}

export const ORGANIZATION_PLACEHOLDERS = {
  Filter: 'Status',
};

export const ORGANIZATION_STATUSES = {
  ORGANIZATION_STATUSES_TEXTS: {
    [Status.Active]: 'Active',
    [Status.Demo]: 'Demo',
    [Status.Inactive]: 'Inactive',
  },
  ORGANIZATION_STATUSES_INDICATOR_TYPES: {
    [Status.Demo]: STATUSES.PENDING,
    [Status.Active]: STATUSES.SUCCESS,
    [Status.Inactive]: STATUSES.ARCHIVED,
  },
};

export const ORGANIZATION = 'organization';
export const ROOT_SLICE = ORGANIZATION;

export const MOCK_DATA = {
  data: {
    organizations: [
      {
        id: 'minutemedia',
        name: 'minutemedia',
        products: ['administration', 'marketplace'],
        properties: [
          'property1',
          'property2',
          'property3',
        ],
        status: Status.Demo,
      },
      {
        id: 'org2',
        name: 'second Org',
        products: null,
        properties: [
          'property5',
        ],
        status: Status.Inactive,
      },
      {
        id: 'org3',
        name: 'org3',
        products: ['marketplace', 'cms'],
        properties: [
          'property1',
          'property2',
        ],
        status: Status.Active,
      },
    ],
  },
};

export const MOCK_PRODUCTS = {
  data: {
    products: [
      { id: 'administration', name: 'Administration' },
      { id: 'auto_bid', name: 'auto bid' },
      { id: 'injections', name: 'Injections' },
      { id: 'marketplace', name: 'Marketplace' },
      { id: 'monti_migration', name: 'Monti Migration' },
      { id: 'organization_setup', name: 'Organization Setup' },
      { id: 'promotion_manager', name: 'Promotion manager' },
      { id: 'site_policy', name: 'Site policy' },
    ],
  },
};

export const DEFAULT_IMAGE_FIELD_FILE_PROVIDER = 'UserUpload';
