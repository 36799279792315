const SEO_BASE_ROUTE = '/seo';

export const SEO_ROUTES = {
  BASE: SEO_BASE_ROUTE,
  REDIRECTIONS: `${SEO_BASE_ROUTE}/redirections`,
  NEW_REDIRECTION: `${SEO_BASE_ROUTE}/redirections/:tenant/:organization/:property`,
  BULK_REDIRECTIONS: `${SEO_BASE_ROUTE}/bulk-redirections`,
  BULK_DELETE: `${SEO_BASE_ROUTE}/bulk-delete`,
};

export const REDIRECTIONS = 'Redirections';

export const INVALID_PATH_CHARACTERS_REGEX = /([^0-9a-zA-Z-/%_.])/;

export const RULE_TYPES = [{ value: 'Contains', content: 'Contains' }, { value: 'StartsWith', content: 'Starts With' }];

export const SEO = 'seo';
export const ROOT_SLICE = SEO;
