import { navigateToPage } from '../../core/routing/history';

const MARKETPLACE_ROUTE = 'marketplace';
const PUBLISHERS_ROUTE = 'publishers';

export const MARKETPLACE_ROUTES = {
  MARKETPLACE_BASE: `/${MARKETPLACE_ROUTE}`,
  PUBLISHERS: `/${MARKETPLACE_ROUTE}/${PUBLISHERS_ROUTE}`,
  CREATE_PUBLISHER: `/${MARKETPLACE_ROUTE}/${PUBLISHERS_ROUTE}/new`,
  EDIT_PUBLISHER: `/${MARKETPLACE_ROUTE}/${PUBLISHERS_ROUTE}/:id`,
};

export const marketplaceNavigator = {
  toPublishersDashboard() {
    navigateToPage(MARKETPLACE_ROUTES.PUBLISHERS);
  },
  toCreatePublisher() {
    navigateToPage(MARKETPLACE_ROUTES.CREATE_PUBLISHER);
  },
  toEditPublisher(publisherId: number) {
    navigateToPage(`${MARKETPLACE_ROUTES.PUBLISHERS}/${publisherId}`);
  },
};
