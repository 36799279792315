export const ALERTS = 'alerts';
const MONITORS = '/monitors';
const QUERIES = '/queries';
const BASE_ROUTE = `/${ALERTS}`;

export const ALERTS_ROUTES = {
  BASE: BASE_ROUTE,
  ALERTS_MONITORS: `${BASE_ROUTE}${MONITORS}`,
  ALERTS_QUERIES: `${BASE_ROUTE}${QUERIES}`,
};
