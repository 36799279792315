import { SortingConfig, Comments } from '@ftbpro/mm-admin-ui-components';

export enum ProposalStatus {
  Pending = 'pending',
  Rejected = 'rejected',
  Review = 'offerInReview',
  Enrolled = 'enrolledInCourses',
  Contracts = 'contractsPending',
  Completed = 'completed',
}

export type Answer = {
  question: string,
  answer: string,
  order: number,
};

export type ProposalSlimFromServer = {
  creator: {
    country: string,
    email: string,
    id: string,
    firstName: string,
    lastName: string,
    state: string,
  },
  id: string,
  lastUpdatedAt: string,
  firstSubmittedAt: string,
  organization: string,
  property: string,
  status: string,
  templateID: string,
  vertical: string,
}[]

export interface ProposalFromServer {
  comments?: Comments.CommentType[],
  creator: {
    country: string,
    email: string,
    id: string,
    firstName: string,
    lastName: string,
    state: string,
    dateOfBirth: string,
    picture?: string,
  },
  id: string,
  lastUpdatedAt: string,
  firstSubmittedAt: string,
  organization: string,
  property: string,
  status: string,
  templateID: string,
  vertical: string,
  filledQuestionnaires: { id: string, name: string, questions: Answer[] }[],
  requiredContracts: string[],
  requiredCourses: string[],
  requiredQuestionnaires: string[]
  reviewerID: string,
  roles: string[],
}

export interface Proposal {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  country: string;
  state: string;
  email: string;
  type: string;
  proposalAdded: string;
  proposalLastUpdated: string;
  // offerSent: string;
  // offerLastUpdated: string;
  answers: Answer[];
  proposalStatus: string;
  offer?: Offer;
  comments?: Comments.CommentType[];
}

export interface OfferFromServer {
  type?: string;
  sites?: string[];
  pointOfContact?: string;
  contracts?: string[];
}

export interface Offer {
  type?: string;
  sites?: string[];
  pointOfContact?: string;
  contracts?: Contracts;
}

export interface Contracts {
  [key: string]: string;
}

export interface ProposalSliceState {
  currentProposal: Proposal;
  offer: Offer;
  loading: boolean;
  error: string;
  sites: string[];
  contractsErrors: Record<string, string>;
  isOfferPageModalOpen: boolean;
  isRejectionModalOpen: boolean;
  proposalPageScroll: Scroll;
}

export interface ProposalDashboardSliceState {
  loading: boolean;
  proposals: ProposalSlimFromServer;
  error: string;
  queryParams: ProposalsParams;
  pagination: {
    next: string;
    prev: string;
    count: number;
  };
}

export interface ProposalRow {
  name: string;
  email: string;
  type: string;
  status: JSX.Element;
  submissionDate: string;
  proposalId: string;
}

export interface ProposalsParams {
  status?: 'All' | ProposalStatus;
  type?: string;
  search?: string;
  sortingConfig?: SortingConfig;
}

export interface ProposalsParamsFromServer {
  status?: ProposalStatus;
  template?: string;
  orderBy?: string;
  sortOrder?: 'asc' | 'desc';
}

export enum sortByParam {
  name = 'name',
  email = 'email',
  submissionDate = 'submissionDate',
  type = 'type'
}

export interface Scroll {
  top: boolean;
  offer: boolean;
  answers: boolean;
  comments: boolean;
}
